import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from 'configs/app.config';
import Cookies from 'js-cookie';

const UPLOAD_ENDPOINTS = [
    'updateContractWarranty',
    'createContractWarranty',
    'createContractDocument',
    'updateContractDocument',
];

const baseQuery = fetchBaseQuery({
    baseUrl: appConfig.apiPrefix,
    credentials: 'include',
    prepareHeaders: (headers, {getState, endpoint}) => {
        const token = getState().auth.token;
        const csrf = Cookies.get('XSRF-TOKEN');

        if (csrf) {
            headers.set('X-Xsrf-Token', csrf);
        }

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');

        if (!UPLOAD_ENDPOINTS.includes(endpoint) && !headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }

        return headers;
    },
});

const baseQueryWithLogout = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    /*if (result?.data?.message) {
        api.dispatch(setSessionMessage({message: result?.data.message, type: 'success'}));
    }

    if (result?.error) {
        api.dispatch(setSessionMessage({message: result?.error.data.message, type: 'danger'}));

        if (unauthorizedCode.includes(result?.error?.status)) {
            api.dispatch(onSignOutSuccess());
        }
    }*/

    return result;
};

export const apiSlice = createApi({
    reducerPath: 'reduxApi',
    baseQuery: baseQueryWithLogout,
    tagTypes: [],
    keepUnusedDataFor: process.env.NODE_ENV === 'development' ? 1 : 60,
    refetchOnFocus: process.env.NODE_ENV !== 'development',
    refetchOnReconnect: process.env.NODE_ENV !== 'development',
    endpoints: builder => ({}),
});
