import {useDispatch, useSelector} from 'react-redux';
import {initialState, setUser} from 'store/auth/userSlice';
import {apiSignOut} from 'services/AuthService';
import {onSignOutSuccess} from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import {useNavigate} from 'react-router-dom';

function useAuth() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    // const query = useQuery();

    const {signedIn} = useSelector(state => state.auth.session);

    /*const signIn = async values => {
        try {
            const resp = await apiSignIn(values);
            if (resp.data) {
                dispatch(onSignInSuccess());
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            },
                        ),
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const signInSocialite = async values => {
        try {
            dispatch(onSignInSuccess());
            if (values.user) {
                dispatch(
                    setUser(
                        values.user || {
                            avatar: '',
                            userName: 'Anonymous',
                            authority: ['USER'],
                            email: '',
                        },
                    ),
                );
            }
            const redirectUrl = query.get(REDIRECT_URL_KEY);
            navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
            return {
                status: 'success',
                message: '',
            };
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const signUp = async values => {
        try {
            const resp = await apiSignUp(values);
            if (resp.data) {
                dispatch(onSignInSuccess());
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            },
                        ),
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
                errors: errors?.response?.data?.errors,
            };
        }
    };*/

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        await apiSignOut();
        handleSignOut();
    };

    return {
        authenticated: signedIn,
        // signIn,
        // signUp,
        signOut,
        // signInSocialite,
    };
}

export default useAuth;
